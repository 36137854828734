@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply bg-zinc-50 text-zinc-800;

  margin: 0;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Helvetica Neue", Helvetica, Tahoma, Arial, Roboto, sans-serif;
  transition: all 0.5s linear;
}

#app {
  width: 100%;
  height: 100%;
}

.state-dropdown__input {
  outline: none;
  border: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.state-dropdown__input:focus {
  outline: none;
  border: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
